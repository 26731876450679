<!--
 * @Descripttion: 
 * @version: 
 * @Author: zhangqinggang
 * @Date: 2020-11-24 18:45:49
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-05-12 19:23:54
-->
<template>
  <a-form id="components-form-demo-normal-login" :form="form" class="login-form">
    <a-form-item>
      <div class="areaCode">
        <img class="plus" src="@/assets/img/add-btn.png" alt="" /> 86
        <img class="right" src="@/assets/img/right-btn.png" alt="" />
      </div>
      <a-input
        v-decorator="[
          'mobile',
          {
            rules: [
              {
                required: true,
                message: '请输入你的手机号!',
              },
              {
                pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
                message: '不是正确的手机格式!',
              },
            ],
          },
        ]"
        placeholder="手机号"
        class="phoneInput"
      >
      </a-input>
    </a-form-item>

    <a-form-item>
      <a-input
        v-decorator="[
          'code',
          {
            rules: [
              { required: true, message: '请输入验证码!' },
              {
                pattern: /^\d{4}$/,
                message: '验证码格式不正确!',
              },
            ],
          },
        ]"
        class="CaptchaIpnt"
        type="code"
        placeholder="验证码"
      >
        <a-icon slot="prefix" type="code" style="color: rgba(0, 0, 0, 0.25)" />
      </a-input>
      <!-- 发送验证码 -->
      <div class="authCodeBtnRegister">
        <a-button @click="sendVerifyCode" v-if="isSend">获取验证码</a-button>
        <a-button v-if="!isSend" disabled>{{ count }}s后可再次发送</a-button>
      </div>
    </a-form-item>

    <a-form-item>
      <a-input
        v-decorator="[
          'schoolNum',
          {
            rules: [
              { required: true, message: '请输入学校ID' },
              {
                pattern: /^\d{6}$/,
                message: '不是正确的学校ID格式!',
              },
            ],
          },
        ]"
        type="schoolId"
        placeholder="学校ID"
      >
        <a-icon slot="prefix" type="idcard" style="color: rgba(0, 0, 0, 0.25)" />
      </a-input>
    </a-form-item>

    <a-form-item>
      <a-checkbox :checked="checkAgree" @change="handleChange"> 同意 </a-checkbox>
      <a class="protocol" href="https://cloud.jsxniu.com/service" target="_blank"
        >《服务协议》</a
      >
      <a class="policy" href="https://cloud.jsxniu.com/privacy" target="_blank"
        >《隐私政策》</a
      >
    </a-form-item>

    <a-form-item>
      <a-button
        type="primary "
        class="nextBtn-Ru"
        html-type="submit"
        @click="handleLogin()"
      >
        下一步
      </a-button>
      <div class="directItems-u">
        <span>已有小牛账号，<a class="directLogin" @click="goLogin()">直接登录</a></span>
      </div>
    </a-form-item>
  </a-form>
</template>

<script>
export default {
  data() {
    return {
      checkAgree: true,
      isSend: true,
      timer: null,
      count: 60,
    };
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: "normal_login" });
  },
  methods: {
    //发送验证码
    sendVerifyCode() {
      this.form.validateFields(["mobile"], (err, values) => {
        if (!err) {
          const TIME_COUNT = 60;
          if (!this.timer) {
            this.count = TIME_COUNT;
            this.isSend = false;
            //这里可以插入$axios调用后台接口
            //验证手机号是否注册过
            this.$api.isExist({ phone: values.mobile }).then((res) => {
              if (!res.success) {
                this.$api
                  .sendSmsCode({ phone: values.mobile, smsCodeType: 1 })
                  .then((res) => {
                    // console.log(res);
                    this.$message.info(res.message);
                    if (res.success) {
                      return;
                    }
                  });
              } else {
                this.$message.error("该手机号已被注册");
              }
            });

            this.timer = setInterval(() => {
              if (this.count > 0 && this.count <= TIME_COUNT) {
                this.count--;
              } else {
                this.isSend = true;
                clearInterval(this.timer);
                this.timer = null;
              }
            }, 1000);
          }
        }
      });
    },
    //复选框状态改变
    handleChange(e) {
      this.checkAgree = e.target.checked;
    },
    //下一步
    handleLogin() {
      this.form.validateFields((err, values) => {
        if (!err) {
          if (this.checkAgree) {
            //  console.log(values);
            //验证手机号和验证码是否匹配
            this.$api
              .verifyCode({
                phone: values.mobile,
                smsCode: values.code,
                smsCodeType: 1,
              })
              .then((res) => {
                if (res.success) {
                  // //调用父组件方法
                  this.$store.state.registerPhone = values.mobile;
                  this.$store.state.schoolNum = values.schoolNum;
                  this.$parent.showFromSgin();
                } else {
                  this.$message.error(res.message);
                }
              });
          } else {
            this.$message.error("请选择同意服务协议和统一登录隐私政策");
          }
        }
      });
    },

    goLogin() {
      this.$router.push("login");
    },
  },
  destroyed() {
    clearInterval(this.timer);
  },
  //在页面离开时做的操作
  beforeRouteLeave(to, from, next) {
    this.$destroy();
    next();
  },
};
</script>
<style lang="scss" scoped>
#components-form-demo-normal-login .login-form {
  max-width: 300px;
}
#components-form-demo-normal-login .login-form-forgot {
  float: right;
}
#components-form-demo-normal-login .login-form-button {
  width: 100%;
}
.CaptchaIpnt {
  width: 200px !important;
}
.areaCode {
  color: #009fe8;
  position: absolute;
  left: 14px;
  top: -11px;
  z-index: 99;
  font-size: 16px;
}
.plus {
  width: 8px;
  height: 8px;
}
.right {
  width: 12px;
  height: 12px;
}
.login-form-forgot {
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #009fe8;
  opacity: 1;
  text-decoration: none !important;
}
.login-form-button {
  width: 348px;
  height: 36px;
  font-size: 14px;
  line-height: 14px;
  margin-top: 20px;
  background: #009fe8;
  border-radius: 18px;
}

.phoneInput {
  padding-left: 70px !important;
}
.CaptchaIpnt {
  width: 200px !important;
}
.nextBtn-Ru {
  width: 348px;
  height: 36px;
  background: #009fe8;
  opacity: 1;
  border-radius: 18px;
  font-size: 14px;
  line-height: 14px;
}
.directItems-u {
  position: relative;
  top: 10px;
  left: 108px;
  font-size: 12px;
  width: 350px;
  height: 30px;
}
.authCodeBtnRegister {
  position: absolute;
  top: -8px;
  right: -150px;
}
</style>
