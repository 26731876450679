<template>
  <div>
    <!--面包屑-->
    <BreadUserManagement></BreadUserManagement>

    <div class="container-acl-user">
      <div class="container-total">
        <!--用户总数-->
        <div class="title">
          <div class="title-text">用户管理（{{ totalCount }}个）</div>
        </div>
        <!--一根线条-->
        <div class="line"></div>
        <!--下面的部分-->
        <div class="user-list">
          <!--搜索框和添加用户还有全删-->
          <div class="user-manage d-f j-b a-c">
            <div class="search d-f a-c">
              <a-form-model
                :model="statusForm"
                :label-col="labelCol1"
                :wrapper-col="wrapperCol1"
                class="modal"
              >
                <a-form-model-item label="状态">
                  <a-select
                    v-model="statusForm.region"
                    :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                  >
                    <a-select-option :value="0">不通过</a-select-option>
                    <a-select-option :value="1">通过</a-select-option>
                    <a-select-option :value="2">全部</a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-form-model>
              <a-input-search
                placeholder="请输入用户名或手机号"
                style="width: 220px; margin-left: 40px"
                @search="onSearch"
                v-model="value"
              />
              <a-button type="primary" @click="onSearch">查询</a-button>
            </div>
            <div>
              <span class="add-user" v-if="userAdd"
                ><a-button type="primary" @click="showModal">
                  <a-icon type="plus" />添加用户
                </a-button></span
              >
              <a-button @click="delAll" class="del-all" v-if="userRemove">
                批量删除
              </a-button>
            </div>
          </div>
          <!--添加用户的弹出框-->
          <a-modal
            v-model="visible"
            title="添加用户"
            @ok="hideModal"
            @cancel="cancelModal"
          >
            <a-form-model
              ref="ruleForm"
              :model="form"
              :rules="rules"
              :label-col="labelCol"
              :wrapper-col="wrapperCol"
            >
              <a-form-model-item label="用户名" prop="username">
                <a-input v-model="form.username" placeholder="请输入用户名" />
              </a-form-model-item>
              <a-form-model-item label="手机号" prop="phone">
                <a-input v-model="form.phone" placeholder="请输入手机号" type="number" />
              </a-form-model-item>
              <a-form-model-item label="用户密码" prop="password">
                <a-input
                  v-model="form.password"
                  placeholder="请输入用户密码"
                  type="password"
                />
              </a-form-model-item>
              <a-form-model-item label="角色" prop="roleId">
                <a-select
                  v-model="form.roleId"
                  placeholder="请选择用户角色"
                  :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                >
                  <a-select-option
                    :value="item.id"
                    :key="item.id"
                    v-for="item in roleList"
                    >{{ item.roleName }}</a-select-option
                  >
                </a-select>
              </a-form-model-item>
              <a-form-model-item
                label="学校编号"
                prop="schoolNum"
                v-if="form.roleId === '1346028046901112833'"
              >
                <a-input
                  v-model="form.schoolNum"
                  placeholder="请输入学校编号"
                  type="number"
                />
              </a-form-model-item>
            </a-form-model>
          </a-modal>
          <!--列表-->
          <div class="user-space-list">
            <!--表格-->
            <a-table
              v-if="userList"
              :columns="columns"
              :data-source="userData"
              :pagination="false"
              :scroll="{ x: 1300 }"
              rowKey="id"
              :row-selection="{
                selectedRowKeys: selectedRowKeys,
                onChange: onSelectChange,
              }"
            >
              <span slot="isReviewed" slot-scope="text">
                <span :class="text === true ? 'ok' : 'no'">{{
                  text | handleReviewed
                }}</span>
              </span>
              <span slot="action" slot-scope="text, record">
                <a @click="edit(record)" v-if="userUpdate && record.id != userInfo.id"
                  >编辑</a
                >
                <a-divider
                  type="vertical"
                  v-if="userUpdate && userRemove && record.id != userInfo.id"
                />
                <a @click="del(record)" v-if="userRemove && record.id != userInfo.id"
                  >删除</a
                >
                <a @click="toUser" v-if="record.id === userInfo.id">个人中心</a>
              </span>
            </a-table>
            <!--编辑的弹出框-->
            <a-modal
              v-model="editVisible"
              title="修改用户信息"
              @ok="hideEdit"
              @cancel="cancelEdit"
            >
              <a-form-model
                ref="ruleEditForm"
                :model="editForm"
                :rules="editRules"
                :label-col="labelCol"
                :wrapper-col="wrapperCol"
              >
                <a-form-model-item label="用户名" prop="username">
                  <a-input v-model="editForm.username" placeholder="请输入用户名" />
                </a-form-model-item>
                <a-form-model-item label="手机号" prop="phone">
                  <a-input v-model="editForm.phone" placeholder="请输入手机号" />
                </a-form-model-item>
                <a-form-model-item label="用户密码" prop="password">
                  <a-input
                    v-model="editForm.password"
                    placeholder="可不填，默认是上一次密码"
                    type="password"
                  />
                </a-form-model-item>
                <a-form-model-item label="角色" prop="roleId">
                  <a-select
                    v-model="editForm.roleId"
                    placeholder="请选择用户角色"
                    :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                    disabled="true"
                  >
                    <a-select-option
                      :value="item.id"
                      v-for="item in roleList"
                      :key="item.id"
                      >{{ item.roleName }}</a-select-option
                    >
                  </a-select>
                </a-form-model-item>
                <a-form-model-item
                  label="学校编号"
                  v-if="editForm.roleId === '1346028046901112833'"
                >
                  <a-input
                    v-model="editForm.schoolNum"
                    placeholder="请输入学校编号"
                    disabled
                  />
                </a-form-model-item>
                <a-form-model-item label="状态">
                  <a-radio-group v-model="editForm.isReviewed">
                    <a-radio :value="true">通过</a-radio>
                    <a-radio :value="false">不通过</a-radio>
                  </a-radio-group>
                </a-form-model-item>
              </a-form-model>
            </a-modal>
            <!--分页-->
            <div class="pagination d-f a-c">
              <a-pagination
                v-model="pageIndex"
                :pageSize="pageSize"
                :default-current="1"
                :total="totalCount"
                :hideOnSinglePage="true"
                show-less-items
                @change="onChange"
                :item-render="itemRender"
                class="pagination"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BreadUserManagement from "~c/bread/BreadUserManagement.vue";
import MD5 from "~l/md5.js";
const columns = [
  {
    title: "用户编号",
    dataIndex: "id",
    width: 250,
    align: "center",
  },
  {
    title: "用户名",
    dataIndex: "username",
    width: 150,
    align: "center",
  },
  {
    title: "手机号",
    dataIndex: "phone",
    width: 200,
    align: "center",
  },
  {
    title: "所属学校",
    dataIndex: "schoolName",
    width: 200,
    align: "center",
  },
  {
    title: "是否审核",
    dataIndex: "isReviewed",
    width: 100,
    align: "center",
    scopedSlots: { customRender: "isReviewed" },
  },
  {
    title: "用户角色",
    dataIndex: "roleNameList",
    width: 250,
    align: "center",
  },
  {
    title: "创建时间",
    dataIndex: "gmtCreate",
    width: 200,
    align: "center",
  },
  {
    title: "操作",
    dataIndex: "action",
    align: "center",
    fixed: "right",
    width: 200,
    scopedSlots: { customRender: "action" },
  },
];
export default {
  components: {
    BreadUserManagement,
  },
  filters: {
    handleReviewed(val) {
      if (val === true) {
        return "通过";
      } else if (val === false) {
        return "不通过";
      } else {
        return "";
      }
    },
  },
  data() {
    return {
      value: "",
      labelCol1: { span: 5 },
      wrapperCol1: { span: 14 },
      statusForm: {
        region: 2,
      },
      visible: false,
      editVisible: false,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      form: {
        username: "",
        phone: null,
        password: "",
        schoolNum: null,
        roleId: undefined,
        isReviewed: true,
      },
      editForm: {
        username: "",
        phone: null,
        password: "",
        schoolNum: null,
        roleId: undefined,
        isReviewed: Boolean,
      },
      rules: {
        username: [
          {
            required: true,
            message: "请输入用户名称",
            whitespace: true,
            trigger: ["change", "blur"],
          },
          {
            validator: (rule, value, callbackFn) => {
              const reg = /^[A-Za-z][0-9A-Za-z]{2,12}$/;
              if (!reg.test(value)) {
                callbackFn("用户名只能包括数字(不以数字开头)和字母且在3~12位之间");
                return;
              }
              callbackFn();
            },
            trigger: ["change", "blur"],
          },
        ],
        phone: [
          {
            required: true,
            message: "请输入手机号",
            whitespace: true,
            trigger: ["change", "blur"],
          },
          {
            validator: (rule, value, callbackFn) => {
              const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
              if (!reg.test(value)) {
                callbackFn("手机号码格式错误");
                return;
              }
              callbackFn();
            },
            trigger: ["change", "blur"],
          },
        ],
        password: [
          {
            required: true,
            message: "请输入用户密码",
            whitespace: true,
            trigger: ["change", "blur"],
          },
          {
            validator: (rule, value, callbackFn) => {
              const reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z\\W]{6,12}$/;
              if (!reg.test(value)) {
                callbackFn("密码必须包括数字和字母且在6~12位之间");
                return;
              }
              callbackFn();
            },
            trigger: ["change", "blur"],
          },
        ],
        schoolNum: [
          {
            required: true,
            message: "请输入学校编号",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        roleId: [
          {
            required: true,
            message: "请选择用户角色",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
      },
      editRules: {
        username: [
          {
            required: true,
            message: "请输入用户名称",
            whitespace: true,
            trigger: ["change", "blur"],
          },
          {
            validator: (rule, value, callbackFn) => {
              const reg = /^[A-Za-z][0-9A-Za-z]{2,12}$/;
              if (!reg.test(value)) {
                callbackFn("用户名只能包括数字(不以数字开头)和字母且在3~12位之间");
                return;
              }
              callbackFn();
            },
            trigger: ["change", "blur"],
          },
        ],
        phone: [
          {
            required: true,
            message: "请输入手机号",
            whitespace: true,
            trigger: ["change", "blur"],
          },
          {
            validator: (rule, value, callbackFn) => {
              const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
              if (!reg.test(value)) {
                callbackFn("手机号码格式错误");
                return;
              }
              callbackFn();
            },
            trigger: ["change", "blur"],
          },
        ],
        password: [
          {
            validator: (rule, value, callbackFn) => {
              const reg = /(^$)|^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z\\W]{6,12}$/;
              if (!reg.test(value)) {
                callbackFn("密码必须包括数字和字母且在6~12位之间");
                return;
              }
              callbackFn();
            },
            trigger: ["change", "blur"],
          },
        ],
        roleId: [
          {
            required: true,
            message: "请选择用户角色",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
      },
      userData: [],
      columns,
      selectedRowKeys: [],
      totalCount: null,
      pageIndex: 1,
      pageSize: 6,
      roleList: [],
      userInfo: null,
    };
  },
  methods: {
    //搜索框
    onSearch() {
      // console.log(this.statusForm.region);
      // console.log(this.value);
      this.getUserList();
    },
    //新增用户
    showModal() {
      this.visible = true;
      this.getRoleList();
    },
    //新增用户确定
    hideModal() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          // this.form.password=MD5(md5(this.form.password))
          // console.log(this.form);
          this.$api.userAdd(this.form).then((res) => {
            // console.log(res);
            if (res.success) {
              this.$message.success("新增用户成功");
              this.getUserList();
              this.form = {
                username: "",
                phone: null,
                password: "",
                schoolNum: null,
                roleId: undefined,
                isReviewed: true,
              };
            } else {
              this.$message.error(res.message);
            }
            this.visible = false;
          });
        }
      });
    },
    //取消新增
    cancelModal() {
      this.form = {
        username: "",
        phone: null,
        password: "",
        schoolNum: null,
        roleId: undefined,
        isReviewed: true,
      };
    },
    //编辑用户
    edit(record) {
      console.log(record);
      this.getRoleList();
      this.editForm.username = record.username;
      this.editForm.phone = record.phone + "";
      this.editForm.isReviewed = record.isReviewed;
      this.editForm.schoolNum = record.schoolNum;
      this.editForm.roleId = record.roleId;
      this.editForm.id = record.id;
      this.editVisible = true;
    },
    //删除用户
    del(record) {
      // console.log(record);
      this.$confirm({
        title: "您确认删除吗",
        onOk: () => {
          this.$api.userDel({ id: record.id }).then((res) => {
            if (res.success) {
              this.$message.success("删除成功");
              this.getUserList();
            } else {
              this.$message.error(res.message);
            }
          });
        },
      });
    },
    //确认编辑
    hideEdit() {
      this.$refs.ruleEditForm.validate((valid) => {
        if (valid) {
          if (this.editForm.password == undefined) {
            this.$api.userUpdate(this.editForm).then((res) => {
              if (res.success) {
                this.$message.success("编辑成功");
                this.getUserList();
                this.editVisible = false;
              } else {
                this.$message.error(res.message);
              }
            });
          } else {
            if (
              this.editForm.password == "" ||
              this.editForm.password.split(" ").join("").length === 0
            ) {
              delete this.editForm["password"];
            }
            this.$api.userUpdate(this.editForm).then((res) => {
              console.log(res);
              if (res.success) {
                this.$message.success("编辑成功");
                this.getUserList();
                this.editVisible = false;
              } else {
                this.$message.error(res.message);
              }
            });
          }
        }
      });
    },
    //取消编辑
    cancelEdit() {
      this.editForm = {
        username: "",
        phone: null,
        password: "",
        schoolNum: null,
        roleId: undefined,
        isReviewed: Boolean,
      };
    },
    //去个人中心
    toUser() {
      this.$router.push("/user");
    },
    //全删
    delAll() {
      if (this.selectedRowKeys.length) {
        this.$confirm({
          title: "您确认删除吗",
          onOk: () => {
            // console.log(this.selectedRowKeys);
            let arr = this.selectedRowKeys;
            let arr1 = arr.filter((r) => r === this.userInfo.id);
            if (arr1.length > 0) {
              this.$message.error("您选择删除的用户中包含自己");
            } else {
              this.$api.userDelAll(this.selectedRowKeys).then((res) => {
                if (res.success) {
                  this.$message.success("批量删除成功");
                  this.getUserList();
                } else {
                  this.$message.error(res.message);
                }
              });
            }
          },
        });
      } else {
        this.$message.error("请选择你想删除的用户");
      }
    },
    //复选框状态改变
    onSelectChange(selectedRowKeys, records) {
      // console.log(records);
      this.selectedRowKeys = selectedRowKeys;
      // console.log(selectedRowKeys);
    },
    //分页状态改变
    onChange(pageNumber) {
      this.pageIndex = pageNumber;
      this.getUserList();
    },
    //改变分页上一步下一步的文字链接
    itemRender(current, type, originalElement) {
      if (type === "prev") {
        return <a>上一页</a>;
      } else if (type === "next") {
        return <a class="next">下一页</a>;
      }
      return originalElement;
    },
    //获取用户列表
    getUserList() {
      this.$api
        .getUserList({
          status: this.statusForm.region,
          search: this.value,
          page: this.pageIndex,
          limit: this.pageSize,
        })
        .then((res) => {
          if (res.success) {
            if (!res.data.items.length && this.pageIndex > 1) {
              this.pageIndex--;
              this.$api
                .getUserList({
                  page: this.pageIndex,
                  limit: this.pageSize,
                  search: this.value,
                  status: this.statusForm.region,
                })
                .then((res) => {
                  this.userData = res.data.items;
                  this.totalCount = res.data.total;
                });
            } else {
              this.userData = res.data.items;
              this.totalCount = res.data.total;
            }
          }
        });
    },
    //查询所有用户角色
    getRoleList() {
      this.$api.getRoleList().then((res) => {
        // console.log(res);
        if (res.success) {
          let roleCode = this.$ls.get("userInfo").roleCode;
          let arr = res.data.items.filter((r) => {
            return r.roleCode <= 100 && r.roleCode >= roleCode;
          });
          // console.log(arr);
          this.roleList = arr;
        }
      });
    },
  },
  created() {
    this.getUserList();
    let userInfo = this.$ls.get("userInfo");
    this.userInfo = userInfo;
  },
  computed: {
    //用户添加按钮的显示隐藏
    userAdd() {
      let userInfo = this.$ls.get("userInfo");
      if (userInfo.permissionValueList.includes("user.add")) {
        return true;
      } else {
        return false;
      }
    },
    //用户删除按钮的显示隐藏
    userRemove() {
      let userInfo = this.$ls.get("userInfo");
      if (userInfo.permissionValueList.includes("user.remove")) {
        return true;
      } else {
        return false;
      }
    },
    //用户编辑按钮的显示隐藏
    userUpdate() {
      let userInfo = this.$ls.get("userInfo");
      if (userInfo.permissionValueList.includes("user.update")) {
        return true;
      } else {
        return false;
      }
    },
    //用户列表的显示隐藏
    userList() {
      let userInfo = this.$ls.get("userInfo");
      if (userInfo.permissionValueList.includes("user.list")) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped src="./user-management.scss"></style>
