<!--
 * @Descripttion: 
 * @version: 
 * @Author: zhangqinggang
 * @Date: 2020-11-24 18:45:49
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-05-12 18:16:20
-->
<template>
  <a-form :form="form">
    <!--  -->
    <!-- 用户名 -->
    <a-form-model ref="ruleForm" :model="ruleForm" :rules="rules">
      <a-form-model-item has-feedback prop="username">
        <a-input
          v-model="ruleForm.username"
          type="user"
          autocomplete="off"
          placeholder="请输入用户名"
        >
          <a-icon slot="prefix" type="user" style="color: rgba(0, 0, 0, 0.25)" />
        </a-input>
      </a-form-model-item>
      <!-- 密码 -->
      <a-form-model-item has-feedback prop="pass">
        <a-input
          v-model="ruleForm.pass"
          type="password"
          autocomplete="off"
          placeholder="6-16位密码，区分大小写"
        >
          <a-icon slot="prefix" type="lock" style="color: rgba(0, 0, 0, 0.25)" />
        </a-input>
      </a-form-model-item>
      <!-- 确认密码 -->
      <a-form-model-item has-feedback prop="checkPass">
        <a-input
          v-model="ruleForm.checkPass"
          type="password"
          autocomplete="off"
          placeholder="确认密码"
        >
          <a-icon
            slot="prefix"
            type="safety-certificate"
            style="color: rgba(0, 0, 0, 0.25)"
          />
        </a-input>
      </a-form-model-item>
    </a-form-model>

    <a-form-item>
      <a-checkbox :checked="checkAgree" @change="handleChange">
        同意
        <a class="protocol" href="https://cloud.jsxniu.com/service" target="_blank"
          >《服务协议》</a
        >
        <a class="policy" href="https://cloud.jsxniu.com/privacy" target="_blank"
          >《隐私政策》</a
        >
      </a-checkbox>
    </a-form-item>

    <a-form-item>
      <a-button
        type="primary "
        class="loginBtn-p"
        html-type="submit"
        @click="submitForm('ruleForm')"
      >
        注册
      </a-button>
      <div class="directItems-p">
        <span>已有小牛账号，<a class="directLogin" @click="goLogin()">直接登录</a></span>
      </div>
    </a-form-item>
  </a-form>
</template>

<script>
import MD5 from "~l/md5.js";
export default {
  data() {
    let validateUser = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入用户名"));
      } else {
        // if (value !== /^[1-9][0-9]{4,10}$/) {
        //   callback(new Error('错误的格式'))
        // }
        callback();
      }
    };

    let validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入你的密码"));
      } else {
        if (this.ruleForm.checkPass !== "") {
          this.$refs.ruleForm.validateField("检查通过");
        }
        callback();
      }
    };
    let validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请确认你的密码"));
      } else if (value !== this.ruleForm.pass) {
        callback(new Error("两次密码不一样"));
      } else {
        callback();
      }
    };
    return {
      // residences,
      autoCompleteResult: [],
      ruleForm: {
        pass: "",
        checkPass: "",
        username: "",
      },
      rules: {
        username: [
          { validator: validateUser, trigger: "change" },
          {
            pattern: /^[A-Za-z][0-9A-Za-z]{2,12}$/,
            message: "用户名只能包括数字(不以数字开头)和字母且在3~12位之间",
          },
          { required: true, message: "请输入你的账户名!" },
        ],
        pass: [
          { validator: validatePass, trigger: "change" },
          {
            pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/,
            message: "密码至少包含数字和英文，长度6-20",
          },
          { required: true, message: "请输入你的密码!" },
        ],
        checkPass: [
          { validator: validatePass2, trigger: "change" },
          {
            pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/,
            message: "密码至少包含 数字和英文，长度6-20",
          },
          { required: true, message: "请确认你的密码!" },
        ],
      },
      checkAgree: true,
    };
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: "register" });
  },
  methods: {
    //注册
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
              if (this.checkAgree) {
                let params = {
                  phone: this.$store.state.registerPhone,
                  schoolNum: this.$store.state.schoolNum,
                  newPassword: this.ruleForm.pass,
                  confirmNewPassword: this.ruleForm.checkPass,
                  // newPassword: MD5.md5(this.ruleForm.pass),
                  // confirmNewPassword: MD5.md5(this.ruleForm.checkPass),
                  username: this.ruleForm.username,
                };
                // console.log(params);
                this.$api.register(params).then((res) => {
                  // console.log(res);
                  if (res.success) {
                    //路由条状
                    this.$message.success("注册成功，请联系管理员审核");
                    this.$router.push("login");
                  } else {
                    this.$message.error(res.message);
                  }
                });
              } else {
                this.$message.error("请选择同意服务协议和统一登录隐私政策");
              }
            }
          });
        }
      });
    },
    //复选框状态改变
    handleChange(e) {
      this.checkAgree = e.target.checked;
    },
    goLogin() {
      //调用父组件方法
      this.$router.push("login");
    },
  },
};
</script>
<style lang="scss" scoped>
.loginBtn-p {
  width: 348px;
  height: 36px;
  background: #009fe8;
  opacity: 1;
  border-radius: 18px;
  font-size: 14px;
  line-height: 14px;
}
.directItems-p {
  position: relative;
  top: 10px;
  left: 108px;
  font-size: 12px;
  width: 350px;
  height: 30px;
}
</style>
