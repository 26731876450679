import { render, staticRenderFns } from "./BreadDevice.vue?vue&type=template&id=5cf978ef&scoped=true&"
import script from "./BreadDevice.vue?vue&type=script&lang=js&"
export * from "./BreadDevice.vue?vue&type=script&lang=js&"
import style0 from "./BreadDevice.vue?vue&type=style&index=0&id=5cf978ef&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.9.8@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5cf978ef",
  null
  
)

export default component.exports