<template>
  <div>
    <div class="d-f j-b total">
      <!--第二行左边-->
      <div class="equipment-detail">
        <div class="map" ref="map"></div>
      </div>
      <!--第二行右边-->
      <div class="app-rank">
        <div class="d-f a-c">
          <div class="common-rank">各省份终端数排名</div>
        </div>
        <div class="d-f ul-and-ec">
          <ul class="second-ul d-f f-d j-a a-c">
            <li v-for="(item, index) in provinceList" :key="index">
              {{ index + 1 }}
            </li>
          </ul>
          <div class="rank-detail" ref="rankMain"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
let echarts = require("echarts");
require("echarts/extension/bmap/bmap");
import imgUrl from "@/assets/img/flag.png"; //引入自定义图标的图片，后面会用到
export default {
  data() {
    return {
      provinceList: [],
      deviceCountList: [],
    };
  },
  watch: {
    provinceList(val, oldVal) {
      this.provinceList = val;
      setTimeout(() => {
        this.myCharts2();
      });
    },
    deviceCountList(val, oldVal) {
      this.deviceCountList = val;
      setTimeout(() => {
        this.myCharts2();
      });
    },
    obj(val, oldVal) {
      //obj的数据有更新的时侯重绘myCharts1
      if (this.isChange(val, oldVal)) {
        this.obj = val;
        setTimeout(() => {
          this.myCharts1();
        });
      }
    },
  },
  props: {
    obj: Object,
  },
  methods: {
    isChange(obj1, obj2) {
      if (Object.keys(obj1).length === 0 || Object.keys(obj2).length === 0) {
        return true;
      } else if (
        obj1.allSchoolCount != obj2.allSchoolCount ||
        obj1.onlineCount != obj2.onlineCount ||
        obj1.totalDeviceCount != obj2.totalDeviceCount ||
        obj1.totalUser != obj2.totalUser
      ) {
        return true;
      } else {
        return false;
      }
    },
    myCharts1() {
      let myChart = echarts.init(this.$refs.map);
      let res_data = this.obj.schoolList;
      let option = {
        title: {
          text: "全国使用小牛智慧黑板概况",
          left: "center",
          top: 16,
          textStyle: {
            fontFamily: "PingFang SC",
            fontWeight: "bold",
            fontSize: 16,
            color: "rgba(0, 0, 0, 0.85)",
          },
        },
        tooltip: {
          trigger: "item",
          backgroundColor: "#ffffff",
          extraCssText: "box-shadow: 0px 2px 8px rgba(0, 159, 232, 0.3)",
          padding: [8, 20, 8, 12],
          formatter: function (val) {
            //返回tooltip的内容及布局样式
            return `
              <div style="font-size: 12px"><span style="color: #FF1414">学校：</span><span style="color: #00AFFF">${val.data.schoolName}</span></div>
              <div style="font-size: 12px"><span style="color: #FF1414">设备数：</span><span style="color: #00AFFF">${val.data.deviceCount}</span><span style="color: #00AFFF">（${val.data.onlineCount}台在线）</span></div>
           `;
          },
        },
        bmap: {
          center: [111.648278, 31.373826], //地图的中心点
          zoom: 5, //地图的等级
          roam: true,
        },
        series: [
          {
            name: "设备数量",
            type: "custom", //type的值为'custom'时，表示自定义图标
            coordinateSystem: "bmap",
            data: res_data,
            renderItem(params, api) {
              let latitude = res_data[params.dataIndex].latitude; //把坐标数据取出来 纬度
              let longitude = res_data[params.dataIndex].longitude; //精度
              let arr = [longitude, latitude];
              //具体实现自定义图标的方法
              return {
                type: "image",
                style: {
                  image: imgUrl, // 自定义的图片地址（上面引入，也可直接写图片的网络地址）
                  x: api.coord(arr)[0], // 图标的经度    必须使用 api.coord（）方法对坐标进行转化之后位置才正确
                  y: api.coord(arr)[1], //图标的纬度
                  width: 20, // 图标大小设置
                  height: 20,
                },
              };
            },
          },
        ],
      };
      myChart.setOption(option);
    },
    myCharts2() {
      let myChart = echarts.init(this.$refs.rankMain);
      let option = {
        grid: {
          top: "0%",
          bottom: "-5.8%",
          left: "0%",
          right: "12%",
          containLabel: true,
        },
        xAxis: {
          show: false,
          type: "value",
        },
        yAxis: {
          axisTick: {
            show: false,
          },
          inverse: true, //倒序
          axisLine: {
            show: false,
            lineStyle: {
              color: "rgba(0,0,0,.45)",
            },
          },
          type: "category",
          data: this.provinceList,
        },
        series: [
          {
            type: "bar",
            emphasis: {
              itemStyle: {
                color: "#00AFFF",
              },
            },
            data: this.deviceCountList,
            barWidth: 10,
            z: 10,
            tooltip: { show: false },
            itemStyle: {
              // 柱子样式
              normal: {
                color: "#00AFFF", // 柱状图颜色
                barBorderRadius: [20],
                legendHoverLink: false,
                label: {
                  show: true, // 显示文本
                  position: ["100%", -1], // 数据值位置
                  formatter: "{c}台",
                  textStyle: {
                    color: "rgba(0, 0, 0, 0.85)",
                    fontSize: 12,
                  },
                },
              },
            },
          },
        ],
      };
      myChart.setOption(option);
    },
    getDeviceOrder() {
      this.$api.getDeviceOrder().then((res) => {
        if (res.success) {
          let arr = res.data.list.filter((r) => r.province != "");
          if (arr.length > 8) {
            let arr1 = arr.slice(0, 8);
            this.provinceList = arr1.map((r) => r.province);
            this.deviceCountList = arr1.map((r) => r.deviceCount);
          } else {
            this.provinceList = arr.map((r) => r.province);
            this.deviceCountList = arr.map((r) => r.deviceCount);
          }
        }
      });
    },
  },
  mounted() {
    setTimeout(() => {
      this.myCharts2();
      this.myCharts1();
      const resizeOb = new ResizeObserver((entries) => {
        for (const entry of entries) {
          echarts.getInstanceByDom(entry.target).resize();
        }
      });
      resizeOb.observe(this.$refs.rankMain);
      resizeOb.observe(this.$refs.map);
    });
  },
  created() {
    this.getDeviceOrder();
  },
};
</script>

<style lang="scss" scoped>
.total {
  .equipment-detail {
    box-sizing: border-box;
    margin-top: 24px;
    min-width: 793px;
    width: 67.5%;
    height: 409px;
    background: #ffffff;
    opacity: 1;
    border-radius: 16px;
    .map {
      width: 100%;
      height: 100%;
      border-radius: 16px;
      overflow: hidden;
    }
  }
  .app-rank {
    margin-top: 24px;
    width: 31.3%;
    min-width: 367px;
    height: 409px;
    background: #ffffff;
    opacity: 1;
    border-radius: 16px;
    padding: 16px;
    .common-rank {
      font-size: 16px;
      font-family: " PingFang SC";
      font-weight: bold;
      line-height: 24px;
      color: rgba(0, 0, 0, 0.85);
      opacity: 1;
    }
    .rank-block {
      .rank-data-picker {
        display: block;
        z-index: 99;
        width: 180px;
        background: #ffffff;
        opacity: 1;
        color: #d9dee5;
        border-radius: 2px;
        font-size: 12px;
        font-family: "PingFang SC";
        font-weight: 400;
      }
      >>> deep .ant-input {
        z-index: 999;
        font-size: 12px;
      }
    }
    .ul-and-ec {
      height: 365px;
      width: 100%;
      .second-ul {
        width: 9%;
        height: 100%;
        li {
          width: 20px;
          height: 20px;
          line-height: 20px;
          text-align: center;
          border-radius: 50%;
          font-size: 12px;
          color: rgba(0, 0, 0, 0.65);
          background: #f0f2f5;
        }
        li:nth-child(-n + 3) {
          background: #f6bd16;
          color: #ffffff;
        }
      }
      .rank-detail {
        width: 90.5%;
        height: 100%;
      }
    }
  }
}
</style>
<style>
.BMap_cpyCtrl {
  display: none;
}
.anchorBL {
  display: none;
}
</style>
