<template>
  <div class="ContainerSet">
    <h1 class="titleSet">修改密码</h1>
    <div class="userinfo-set">
      <a-form :form="form">
        <a-form-item>
          <h3>手机号</h3>
          <a-input
            v-decorator="[
              'phone',
              {
                rules: [
                  { required: true, message: '请输入您的手机号' },
                  {
                    pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
                    message: '不是正确的手机格式!',
                  },
                ],
                initialValue: this.user.phone,
              },
            ]"
            type="Captcha"
            placeholder="请输入手机号"
            disabled
          >
          </a-input>
        </a-form-item>
        <!--  -->
        <a-form-item>
          <a-input
            v-decorator="[
              'code',
              {
                rules: [
                  { required: true, message: '请输入验证码!' },
                  {
                    pattern: /^\d{4}$/,
                    message: '验证码格式不正确!',
                  },
                ],
                initialValue: this.user.code,
              },
            ]"
            style="width: 201px"
            type="code"
            placeholder="请输入验证码"
          >
          </a-input>
          <span class="codeBtn">
            <a-button @click="sendVerifyCode" v-if="isSend">获取验证码</a-button>
            <a-button v-if="!isSend" disabled>{{ count }}s后可再次发送</a-button>
          </span>
        </a-form-item>
        <!--  -->
        <h3>新密码</h3>
        <a-form-model ref="ruleForm" :model="ruleForm" :rules="rules">
          <a-form-model-item has-feedback prop="pass">
            <a-input
              v-model="ruleForm.pass"
              type="password"
              autocomplete="off"
              placeholder="6-16位密码，区分大小写"
            >
            </a-input>
          </a-form-model-item>
          <!--  -->
          <h3>确认新密码</h3>
          <a-form-model-item has-feedback prop="checkPass">
            <a-input
              v-model="ruleForm.checkPass"
              type="password"
              autocomplete="off"
              placeholder="确认密码"
            >
            </a-input>
          </a-form-model-item>
        </a-form-model>
      </a-form>
      <a-button @click="submitForm('ruleForm')" type="primary" class="confirmBtn"
        >确认修改</a-button
      >
    </div>
  </div>
</template>

<script>
import MD5 from "~l/md5.js";
export default {
  data() {
    let validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.ruleForm.checkPass !== "") {
          this.$refs.ruleForm.validateField("检查通过");
        }
        callback();
      }
    };
    let validatePassT = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请重新输入密码"));
      } else if (value !== this.ruleForm.pass) {
        callback(new Error("两次密码不一样"));
      } else {
        callback();
      }
    };
    return {
      user: {
        phone: "",
        code: "",
      },
      ruleForm: {
        pass: "",
        checkPass: "",
      },
      rules: {
        pass: [
          { validator: validatePass, trigger: "change" },
          {
            pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/,
            message: "密码至少包含 数字和英文，长度6-20",
          },
          { required: true, message: "请输入你的密码!" },
        ],
        checkPass: [
          { validator: validatePassT, trigger: "change" },
          {
            pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/,
            message: "密码至少包含 数字和英文，长度6-20",
          },
          { required: true, message: "请确认你的密码!" },
        ],
      },
      id: "",
      count: 60,
      isSend: true,
      timer: null,
    };
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: "register" });
  },
  methods: {
    //发送验证码
    sendVerifyCode() {
      this.form.validateFields(["phone"], (err, values) => {
        if (!err) {
          const TIME_COUNT = 60;
          if (!this.timer) {
            this.count = TIME_COUNT;
            this.isSend = false;
            //这里可以插入$axios调用后台接口
            // console.log(values) //表单的值
            const params = {};
            params.phone = this.user.phone;
            params.smsCodeType = 5;
            console.log(params);
            this.$api.sendSmsCode(params).then((res) => {
              // console.log(res);
              this.$message.info(res.message);
            });

            this.timer = setInterval(() => {
              if (this.count > 0 && this.count <= TIME_COUNT) {
                this.count--;
              } else {
                this.isSend = true;
                clearInterval(this.timer);
                this.timer = null;
              }
            }, 1000);
          }
        }
      });
    },
    //验证密码
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
              // console.log(values); //用户名数据
              // console.log(this.ruleForm); // 密码数据
              let params = {};
              params.id = this.id;
              params.phone = values.phone;
              params.smsCode = values.code;
              params.newPassword = this.ruleForm.pass;
              params.confirmNewPassword = this.ruleForm.checkPass;
              // params.newPassword = MD.md5(this.ruleForm.pass);
              // params.confirmNewPassword = MD.md5(this.ruleForm.checkPass);
              this.$api.updatePassword(params).then((res) => {
                if (res.success) {
                  this.$message.success("修改成功，请重新登录");
                  this.getUserInfo();
                  this.$ls.set("token", null);
                  this.$store.commit("setList", []);
                  this.$router.push("/login");
                } else {
                  this.$message.error(res.message);
                }
              });
            } else {
            }
          });
        }
      });
    },
    //获取用户手机号
    getUserInfo() {
      this.$api.getUserInfo().then((res) => {
        // console.log(res);
        if (res.success) {
          this.$ls.set("userInfo", res.data);
          let userInfo = this.$ls.get("userInfo");
          this.user.phone = userInfo.phone;
          this.id = userInfo.id;
        }
      });
    },
  },
  created() {
    let userInfo = this.$ls.get("userInfo");
    this.user.phone = userInfo.phone;
    this.id = userInfo.id;
  },
  destroyed() {
    clearInterval(this.timer);
  },
  //在页面离开时做的操作
  beforeRouteLeave(to, from, next) {
    this.$destroy();
    next();
  },
};
</script>

<style lang="scss" scope>
@import "./set-password.scss";
</style>
