var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-form',{staticClass:"login-form",attrs:{"id":"components-form-demo-normal-login","form":_vm.form}},[_c('a-form-item',[_c('div',{staticClass:"areaCode"},[_c('img',{staticClass:"plus",attrs:{"src":require("@/assets/img/add-btn.png"),"alt":""}}),_vm._v(" 86 "),_c('img',{staticClass:"right",attrs:{"src":require("@/assets/img/right-btn.png"),"alt":""}})]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'mobile',
        {
          rules: [
            {
              required: true,
              message: '请输入你的手机号!',
            },
            {
              pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
              message: '不是正确的手机格式!',
            } ],
        } ]),expression:"[\n        'mobile',\n        {\n          rules: [\n            {\n              required: true,\n              message: '请输入你的手机号!',\n            },\n            {\n              pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,\n              message: '不是正确的手机格式!',\n            },\n          ],\n        },\n      ]"}],staticClass:"phoneInput",attrs:{"placeholder":"手机号"}})],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'code',
        {
          rules: [
            { required: true, message: '请输入验证码!' },
            {
              pattern: /^\d{4}$/,
              message: '验证码格式不正确!',
            } ],
        } ]),expression:"[\n        'code',\n        {\n          rules: [\n            { required: true, message: '请输入验证码!' },\n            {\n              pattern: /^\\d{4}$/,\n              message: '验证码格式不正确!',\n            },\n          ],\n        },\n      ]"}],staticClass:"CaptchaIpnt",attrs:{"type":"code","placeholder":"验证码"}},[_c('a-icon',{staticStyle:{"color":"rgba(0, 0, 0, 0.25)"},attrs:{"slot":"prefix","type":"code"},slot:"prefix"})],1),_c('div',{staticClass:"authCodeBtnRegister"},[(_vm.isSend)?_c('a-button',{on:{"click":_vm.sendVerifyCode}},[_vm._v("获取验证码")]):_vm._e(),(!_vm.isSend)?_c('a-button',{attrs:{"disabled":""}},[_vm._v(_vm._s(_vm.count)+"s后可再次发送")]):_vm._e()],1)],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'schoolNum',
        {
          rules: [
            { required: true, message: '请输入学校ID' },
            {
              pattern: /^\d{6}$/,
              message: '不是正确的学校ID格式!',
            } ],
        } ]),expression:"[\n        'schoolNum',\n        {\n          rules: [\n            { required: true, message: '请输入学校ID' },\n            {\n              pattern: /^\\d{6}$/,\n              message: '不是正确的学校ID格式!',\n            },\n          ],\n        },\n      ]"}],attrs:{"type":"schoolId","placeholder":"学校ID"}},[_c('a-icon',{staticStyle:{"color":"rgba(0, 0, 0, 0.25)"},attrs:{"slot":"prefix","type":"idcard"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-checkbox',{attrs:{"checked":_vm.checkAgree},on:{"change":_vm.handleChange}},[_vm._v(" 同意 ")]),_c('a',{staticClass:"protocol",attrs:{"href":"https://cloud.jsxniu.com/service","target":"_blank"}},[_vm._v("《服务协议》")]),_c('a',{staticClass:"policy",attrs:{"href":"https://cloud.jsxniu.com/privacy","target":"_blank"}},[_vm._v("《隐私政策》")])],1),_c('a-form-item',[_c('a-button',{staticClass:"nextBtn-Ru",attrs:{"type":"primary ","html-type":"submit"},on:{"click":function($event){return _vm.handleLogin()}}},[_vm._v(" 下一步 ")]),_c('div',{staticClass:"directItems-u"},[_c('span',[_vm._v("已有小牛账号，"),_c('a',{staticClass:"directLogin",on:{"click":function($event){return _vm.goLogin()}}},[_vm._v("直接登录")])])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }